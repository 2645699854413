import { Button, Col, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../App.css';
import { buildingStep, resetTo } from '../app/steps/stepsSlice';


const Building = () => {
  const dispatch = useDispatch();
  const building = useSelector(buildingStep);

  const metrics = [
    {
      building: "A",
      florPlates: "24",
      levels: "7",
      ceilingheit: "12"
    },
    {
      building: "B",
      florPlates: "17",
      levels: "8",
      ceilingheit: "12"
    },
    {
      building: "C",
      florPlates: "18",
      levels: "10",
      ceilingheit: "12"
    },
  ]
  const metric = metrics.find((it: any) => it.building === building)

  return (
    <>
      <Row className="fadeIn description-position" >
        <Col span={20} style={{ padding: "10px" }}>
          <h3 style={{ marginBottom: 0, fontSize: 48 }}>Building {building}</h3>
          <Row>
            <Col span={19}>
              <p style={{ fontSize: 18 }}> Capitol Crossing neighborhood for DC’s next generation of leaders, connecting Capitol Hill and Gallery Place</p>
            </Col>
            <Col span={5}></Col>
          </Row>

          <Row >
            <Col span={4}>
              <p style={{ fontWeight: 'bold', fontSize: 40 }}>{metric?.florPlates}</p>
            </Col>
            <Col span={8}>
              <Col style={{ marginTop: "10px" }} span={24}>K SqFt</Col>
              <Col span={24}>Floorplates</Col>
            </Col>
          </Row>
          <Row >
            <Col span={4}>
              <p style={{ fontWeight: 'bold', fontSize: 40 }}>{metric?.levels}</p>
            </Col>
            <Col span={8}>
              <Col style={{ marginTop: "10px" }} span={24}>Commercial</Col>
              <Col span={24}>Levels</Col>
            </Col>
          </Row>
          <Row >
            <Col span={4}>
              <p style={{ fontWeight: 'bold', fontSize: 40 }}>{metric?.ceilingheit}</p>
            </Col>
            <Col span={8}>
              <Col style={{ marginTop: "10px" }} span={24}>Feet</Col>
              <Col span={24}>Ceilingheit</Col>
            </Col>
          </Row>
          <Button className='nav-hover' type="link" style={{ fontSize: 36, color: '#E55010', fontWeight: 'bold', fontStyle: 'normal', textShadow: ' -1px -1px 3px rgba(51, 51, 51, 0.08)' }}
            onClick={() => dispatch(resetTo(4))}>EXPLORE THE FLOORS</Button>
        </Col>
      </Row>
    </>

  );
}

export default Building;

