import { Button, Col, Row } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import '../App.css';
import { resetTo } from '../app/steps/stepsSlice';


const Site = () => {
  const dispatch = useDispatch();
  return (
      <Row  className="fadeIn description-position">
        <Col span={20} style={{padding:"10px"}}>
          <h3 style={{ marginBottom: 0, fontSize: 48 }}>Capitol Crossing</h3>
          <Row>
            <Col span={19}>
              <p style={{ fontSize: 18 }}> Capitol Crossing neighborhood for DC’s next generation of leaders, connecting Capitol Hill and Gallery Place</p>
            </Col>
            <Col span={5}></Col>
          </Row>
          <Row>
            <Col span={4}>
              <p style={{ fontWeight: 'bold', fontSize: 40 }}>1.2</p>
            </Col>
            <Col span={8}>
              <Col style={{marginTop:"10px"}} span={24}>Billion</Col>
              <Col span={24}>Multiphase Development</Col>
            </Col>
          </Row>
          <Row >
            <Col span={4}>
              <p style={{ fontWeight: 'bold', fontSize: 40 }}>3</p>
            </Col>
            <Col span={8}>
              <Col style={{marginTop:"10px"}} span={24}>New</Col>
              <Col span={24}>City Blocks</Col>
            </Col>
          </Row>
          <Row >
            <Col span={4}>
              <p style={{ fontWeight: 'bold', fontSize: 40 }}>2.2</p>
            </Col>
            <Col span={8}>
              <Col style={{marginTop:"10px"}} span={24}>Million</Col>
              <Col span={24}>Square feet</Col>
            </Col>
          </Row>
          <Button className='nav-hover' type="link" style={{ fontSize: 36, color: '#E55010', fontWeight: 'bold', fontStyle: 'normal', textShadow: ' -1px -1px 3px rgba(51, 51, 51, 0.08)' }}
            onClick={() => dispatch(resetTo(2))} >EXPLORE THE BLOCK</Button>
        </Col>
      </Row>
  );
}

export default Site;
