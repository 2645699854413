import { Button, Col, Row } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import '../App.css';
import { resetTo } from '../app/steps/stepsSlice';


const Block = () => {
  const dispatch = useDispatch();
  return (
    <div>
      <Row className="fadeIn description-position-block" >
        <Col span={20}>
          <h3 style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 50 }}>3 Buildings</h3>
          <Row>
            <Col span={19}>
              <p style={{ fontSize: 18 }}> Capitol Crossing neighborhood for DC’s next generation of leaders, connecting Capitol Hill and Gallery Place</p>
            </Col>

          </Row>
          <Button className='nav-hover' type="link" style={{ fontSize: 36, color: '#E55010', fontWeight: 'bold', fontStyle: 'normal', textShadow: ' -1px -1px 3px rgba(51, 51, 51, 0.08)' }} 
            onClick={() => dispatch(resetTo(3))} >EXPLORE THE BUILDING</Button>
        </Col>
      </Row>
    </div>
  );
}

export default Block;






