export default function AvailableSteps(currentStep:number): number[] {
    const nextSteps = [
        [1,2],
        [0,2],
        [0,1],
        [0,1,2],
        [0,1,2,3],
        [0,1,2,3,4],
    ]

    return nextSteps[currentStep] || nextSteps[5]
}
