import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface IFloorData {
  name: string;
  description: string;
  sqft: number;
  things: number;
  meetingRooms: number;
}

interface CounterState {
  value: number;
  menuStep: number;
  building: string;
  floorData: IFloorData;
  archilogicScene: string;
  hoveredFloors: number;
}

const initialState: CounterState = {
  value: 0,
  menuStep: 0,
  building: "",
  floorData: {
    name: "",
    description: "",
    things: 0,
    sqft: 0,
    meetingRooms: 0
  },
  archilogicScene: "",
  hoveredFloors: 0
};

export const stepsSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    jumpTo: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
      state.menuStep = action.payload;
      if (action.payload <= 2) state.building = ""
    },
    resetTo: (state, action: PayloadAction<number>) => {
      state.menuStep = action.payload;
    },
    selectBuilding: (state, action: PayloadAction<string>) => {
      state.building = action.payload;
    },
    selectFloor: (state, action: PayloadAction<string>) => {
      const name = action.payload
      console.log(action.payload);

      if (name.includes('A')) {
        state.floorData = {
          name: name,
          description: "Capitol Crossing neighborhood for DC’s next generation of leaders, connecting Capitol Hill and Gallery Place",
          things: 261,
          sqft: 34,
          meetingRooms: 16
        }
      }
        if (name.includes('B')) {
          state.floorData = {
            name: name,
            description: "Capitol Crossing neighborhood for DC’s next generation of leaders, connecting Capitol Hill and Gallery Place",
            things: 212,
            sqft: 25,
            meetingRooms: 8
        }
      }
        if (name.includes('C')) {
          state.floorData = {
            name: name,
            description: "Capitol Crossing neighborhood for DC’s next generation of leaders, connecting Capitol Hill and Gallery Place",
            things: 134,
            sqft: 18,
            meetingRooms: 7
          }
        }
      },
      setArchilogicScene: (state, action: PayloadAction<string>) => {
        state.archilogicScene = action.payload;
      },
        addHovered: (state, action: PayloadAction<boolean>) => {
          if (action.payload === true) {
            state.hoveredFloors += 1;
          }
          else {
            state.hoveredFloors -= 1;
          }
          if (state.hoveredFloors < 0) state.hoveredFloors = 0
          document.body.style.cursor = (state.hoveredFloors > 0) ? 'pointer' : 'auto'
        },
  },
  });

export const { jumpTo, resetTo, selectFloor, selectBuilding, setArchilogicScene, addHovered } = stepsSlice.actions;

export const selectStep = (state: RootState) => state.counter.value;
export const menuStep = (state: RootState) => state.counter.menuStep;
export const buildingStep = (state: RootState) => state.counter.building;
export const floorData = (state: RootState) => state.counter.floorData;
export const archilogicScene = (state: RootState) => state.counter.archilogicScene;
export const hoveredFloors = (state: RootState) => state.counter.hoveredFloors;

export default stepsSlice.reducer;
