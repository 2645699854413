import React, { Suspense } from 'react';
import { Bloom, DepthOfField, EffectComposer, SMAA, SSAO } from 'react-postprocessing';
import { Canvas } from 'react-three-fiber';
//import Model from './Model';
import Model from './Base_levels';



const CanvasThreeJS = (props: any) => {
  const { step, menuStep, building, floor, postProcessing } = props

  const ambientLight = (step > 4) ? 10 : 1.5;

  return (
    <Canvas invalidateFrameloop={step > 4} concurrent style={{ position: 'absolute', top: '0', zIndex: 1, overflow: 'hidden' }} id="canvas">
      <Suspense fallback={null}>

        <ambientLight intensity={ambientLight} color={0xffffff} />
        <Model step={step} menuStep={menuStep} building={building} floor={floor} />

        {postProcessing && <EffectComposer>
          <SSAO
            intensity={30}
            luminanceInfluence={0.2}
            radius={4}
            scale={0.5}
            bias={0.5}
            distanceThreshold={0.8}
            distanceFalloff={0.03}
            rangeFalloff={0.1}
          />

        </EffectComposer>}


      </Suspense>
    </Canvas>
  );
}

export default CanvasThreeJS;
