import ArchilogicEmbed, { PresentationMode } from '@archilogic/embed-api';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './App.css';
import {
  menuStep, buildingStep, selectStep, floorData, archilogicScene
} from './app/steps/stepsSlice';
import CanvasThreeJS from './components/CanvasThreeJS';
import NavBar from './components/NavBar';
import OverlayPages from './components/OverlayPages';
import Fps from './utils/fps';

export const colorUnselected = '#b4b4b4'
export const colorSelected = '#e55010'

declare global {
  interface Window { v: any; }
}

const App = () => {
  const step = useSelector(selectStep);
  const menuStepVal = useSelector(menuStep);
  const building = useSelector(buildingStep);
  const floor = useSelector(floorData);
  const archilogicSceneId = useSelector(archilogicScene);


  const [postProc, setPostProc] = useState<Boolean>();

  const divRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const usePostProcessing = urlParams.get('postProcessing');
    setPostProc(usePostProcessing === 'true')

    const container = divRef.current
    const v = new ArchilogicEmbed(container, {
      transparentBackground: true,
      presentationMode: PresentationMode.jumpToFirstBookmark,
      lowResTexturesOnly: true,
      showTitle: false,
      showLogo: false,
      minimap: false,
      uiButtons: {
        birdMode: false,
        personMode: false,
        fullscreen: false,
        bookmarkStrip: false,
        share: false,
        help: false,
        presentation: false
      }
    });


    if (!window.v) window.v = v

  }, [])

  useEffect(() => {
    const container = divRef.current
    container.hidden = (step <= 4) ? true : false
  }, [step])

  return (
    <>
      <div id="embed" ref={divRef} style={{ position: 'absolute', top: 0, height: '100%', width: '100%' }}></div>
      {step === 4 && <div id="custom-message" className="fadeBackground"  >Double-Click to access inside</div>}

      <NavBar />
      <OverlayPages />
      <CanvasThreeJS postProcessing={postProc} step={step} menuStep={menuStepVal} building={building} floor={floor.name} />
      {/* <Fps /> */}

    </>
  );
}

export default App;

