import { Button, Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../App.css';
import { archilogicScene, resetTo, selectStep } from '../app/steps/stepsSlice';
import View3D from '../components/View3D';

const Unit = () => {
  const dispatch = useDispatch();
  const archilogicSceneString = useSelector(archilogicScene);
  const loading = true
  const step = useSelector(selectStep);

  return (
    <>

      <Row >

        <div style={{ width: '100vw', height: '100vh' }}>
          <div className={'floorplan-position'} style={{ opacity: '100%' }}>
            <View3D sceneId={archilogicSceneString}  />
          </div>
        </div>
        {step === 5 &&
          <Col className='description-position-floor' span={10}>
            <h3 style={{ marginBottom: 0, fontSize: 48 }}>Unit</h3>
            <Row>
              <Col span={19}>
                <p style={{ fontSize: 18 }}> Capitol Crossing neighborhood for DC’s next generation of leaders, connecting Capitol Hill and Gallery Place</p>
              </Col>
              <Col span={5}></Col>
            </Row>
            <Row >
              <Col span={4}>
                <p style={{ fontWeight: 'bold', fontSize: 40 }}>1.2</p>
              </Col>
              <Col span={8}>
                <Col style={{ marginTop: "10px" }} span={24}>Billion</Col>
                <Col span={24}>Multiphase Development</Col>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <p style={{ fontWeight: 'bold', fontSize: 40 }}>3</p>
              </Col>
              <Col span={8}>
                <Col style={{ marginTop: "10px" }} span={24}>New</Col>
                <Col span={24}>City Blocks</Col>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <p style={{ fontWeight: 'bold', fontSize: 40 }}>2.2</p>
              </Col>
              <Col span={8}>
                <Col style={{ marginTop: "10px" }} span={24}>Million</Col>
                <Col span={24}>Square feet</Col>
              </Col>
            </Row>
            <Button className='nav-hover' type="link"  style={{fontSize: 36, color: '#E55010', fontWeight: 'bold', fontStyle: 'normal', textShadow: ' -1px -1px 3px rgba(51, 51, 51, 0.08)' }}
              onClick={() => dispatch(resetTo(4))} >BACK TO FLOOR</Button>
          </Col>
        }
      </Row>

    </>
  );
}




export default Unit;

