import { Button, PageHeader } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    jumpTo,
    resetTo,
    selectStep
} from '../app/steps/stepsSlice';
import AvailableSteps from '../utils/AvailableSteps';


const NavBar = () => {
    const dispatch = useDispatch();
    const step = useSelector(selectStep);
    const nextSteps = AvailableSteps(step)

    const buttons = ["City", "Site", "Block", "Building", "Floor"]


    return (
        <PageHeader style={{ zIndex: 10 }}

            extra={buttons.map((label, index) =>
                <Button
                    className='nav-hover'
                    key={index} type="link"
                    style={{ padding: 20, fontSize: '26px', fontWeight: 700, color: index === step ? '#E55010' : '#000000' }}
                    onClick={() => {
                        if (nextSteps.includes(index))
                            dispatch(resetTo(index))
                    }} >
                    {label}
                </Button>
            )}
        />
    )
}

export default NavBar;


