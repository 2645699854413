
import React, { useEffect, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { colorSelected, colorUnselected } from '../App';
import { addHovered, resetTo } from '../app/steps/stepsSlice';


const Block = (props: any) => {
  const [hovered, setHover] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addHovered(hovered))
  }, [hovered])

  return (
    <mesh
      {...props}
      onClick={(e) => dispatch(resetTo(2))}
      onPointerOver={() => setHover(true)}
      onPointerOut={() => setHover(false)}>
      <meshStandardMaterial color={hovered ? colorSelected : colorUnselected} />
    </mesh>
  )
}

export default Block
