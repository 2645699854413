import { Button, Col, Row } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../App.css';
import { floorData, resetTo, setArchilogicScene, jumpTo } from '../app/steps/stepsSlice';


const Floor = () => {
  const { name, description, sqft, things, meetingRooms } = useSelector(floorData);

  const dispatch = useDispatch();

  return (



    <div className="fadeIn description-position">
    <Row >
      <Col span={20} style={{padding:'10px'}}>
        <h3 style={{ marginBottom: 0, fontSize: 48 }}>Level - {name}</h3>
        <Row>
          <Col span={19}>
            <p style={{ fontSize: 18 }}> {description}</p>
          </Col>
          <Col span={5}></Col>
        </Row>

        <Row >
          <Col span={4}>
            <p style={{ fontWeight: 'bold', fontSize: 40 }}>{sqft}</p>
          </Col>
          <Col span={8}>
          <Col style={{marginTop:"10px"}} span={24}>K SqFt</Col>
              <Col span={24}>Floor Area</Col>
          </Col>
        </Row>
        <Row >
          <Col span={4}>
            <p style={{ fontWeight: 'bold', fontSize: 40 }}>{things}</p>
          </Col>
          <Col span={8}>
          <Col style={{marginTop:"10px"}} span={24}>Headcount</Col>

          </Col>
        </Row>
        <Row >
          <Col span={4}>
            <p style={{ fontWeight: 'bold', fontSize: 40 }}>{meetingRooms}</p>
          </Col>
          <Col span={8}>
          <Col style={{marginTop:"10px"}} span={24}>Meeting</Col>
              <Col span={24}>Rooms</Col>
          </Col>
        </Row>

        <Button className='nav-hover' type="link" style={{ fontSize: 36, color: '#E55010', fontWeight: 'bold', fontStyle: 'normal', textShadow: ' -1px -1px 3px rgba(51, 51, 51, 0.08)' }}
          onClick={() => dispatch(resetTo(3))} >BACK TO BUILDING</Button>
      </Col>
    </Row>
  </div>

  );
}

export default Floor;

