import ArchilogicEmbed, { PresentationMode } from '@archilogic/embed-api';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import VizSensor from 'react-visibility-sensor';
import { jumpTo } from '../app/steps/stepsSlice';
interface View3DProps {
    sceneId: string | null,
}

const View3D = React.memo((props: View3DProps) => {
    const { sceneId } = props
    const [viewer, setViewer] = useState<any>(null)
    const [bookmarks, setBookmarks] = useState<any[]>([])
    // let currentIndex = 0, scrollTarget: any
    const scrollMargin = window.innerHeight * 1.5
    const [loading, setLoading] = useState<any>(true)

    // const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    const publishableAccessToken = process.env.REACT_APP_PUBLISHABLE_TOKEN; 

    useEffect(() => {
        if (sceneId) {
            if (viewer !== null) {
                viewer.loadScene(sceneId, { publishableAccessToken });
                return
            }
            const loadSecene = async () => {
                let v = window.v
                await v.viewerReadyPromise.catch((err: any) => console.error('viewer couldnt be initialized', err))
                await v.loadScene(sceneId, { publishableAccessToken });
                setViewer(v)
                setTimeout(() => setLoading(false), 2000)
            }
            loadSecene()
        }
    }, [sceneId])

    useEffect(() => {
        if (!viewer) return;
        viewer.zoomExtents({ animate: true, jumpToFirstBookmark: true })
        window.scrollTo(0, 0)
        // get camera bookmarks
        viewer.listBookmarks().then((result: any) => {
            setBookmarks(result)
        })
    }, [viewer])

    const dispatch = useDispatch();
    return (

        <>
            { loading &&
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            }
            <div style={{ overflowY: "scroll", height: "100vh" }}>
                <div style={{ height: "100%" }}>
                    {bookmarks.map((b, i) =>
                        <div className={'section'} key={i} style={{ marginBottom: `${scrollMargin}px` }}>
                            <VizSensor
                                partialVisibility={true}
                                onChange={(isVisible) => {
                                    if (isVisible) {
                                        dispatch(jumpTo(i + 5))

                                    }

                                    if (isVisible) viewer.goToBookmark({ bookmarkId: b.id, animate: true })
                                }}
                            >

                                <div className='cta'  >
                                    <span>{b.name}</span>
                                </div>

                            </VizSensor>
                        </div>
                    )}
                </div>
            </div>


        </>

    )
})

export default View3D
