
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { colorSelected, colorUnselected } from '../App';
import { jumpTo, resetTo, selectFloor, setArchilogicScene, addHovered } from '../app/steps/stepsSlice';
import { message } from 'antd';


const Floor = (props: any) => {
  const { scene, name, floor } = props
  const [hovered, setHover] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addHovered(hovered))
  }, [hovered])

  const gotoFloorplan = (scene: string) => {
    dispatch(setArchilogicScene(scene))
    dispatch(jumpTo(5))
  }

  const clickHandler = (e: any) => {
    e.stopPropagation();
    dispatch(resetTo(4));
    dispatch(selectFloor(e.eventObject['name']))
  }

  return (
    <mesh
      {...props}
      onDoubleClick={(e) => { e.stopPropagation(); gotoFloorplan(scene); }}
      onClick={clickHandler}
      onPointerOver={(e) => { e.stopPropagation(); setHover(true); }}
      onPointerOut={(e) => { e.stopPropagation(); setHover(false); }}>
      <meshStandardMaterial color={(hovered || (name === floor)) ? colorSelected : colorUnselected} />
    </mesh>
  )
}

export default Floor