/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/


import { config, useSpring } from "@react-spring/core";
import { a, SpringValue } from '@react-spring/three';
import { useGLTF } from '@react-three/drei/core/useGLTF';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { useFrame, useThree } from 'react-three-fiber';
import { useGesture } from "react-use-gesture";
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import {
  jumpTo
} from '../app/steps/stepsSlice';
import { store } from '../app/store';
import Block from "./Block";
import Building from "./Building";
import Floor from "./Floor";

const clamp = (num: number, a: number, b: number) => Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));

type GLTFResult = GLTF & {
  nodes: {
    Plane009: THREE.Mesh
    Plane014: THREE.Mesh
    Plane017: THREE.Mesh
    Plane020: THREE.Mesh
    Plane023: THREE.Mesh
    Plane028: THREE.Mesh
    Plane031: THREE.Mesh
    Plane034: THREE.Mesh
    Plane083: THREE.Mesh
    Plane084: THREE.Mesh
    Plane085: THREE.Mesh
    Plane086: THREE.Mesh
    Plane089: THREE.Mesh
    Plane090: THREE.Mesh
    Plane091: THREE.Mesh
    block: THREE.Mesh
    building_c: THREE.Mesh
    building_a: THREE.Mesh
    building_b: THREE.Mesh
    Areaslanduse: THREE.Mesh
    Areaslanduse_1: THREE.Mesh
    Plane012: THREE.Mesh
    Plane015: THREE.Mesh
    Plane018: THREE.Mesh
    Plane021: THREE.Mesh
    Plane026: THREE.Mesh
    Plane029: THREE.Mesh
    Plane032: THREE.Mesh
    Plane035: THREE.Mesh
    Plane038: THREE.Mesh
    Plane041: THREE.Mesh
    plane_inner: THREE.Mesh
    plane_outer: THREE.Mesh
  }
  materials: {
    ['Material.006']: THREE.MeshStandardMaterial
    buildings: THREE.MeshStandardMaterial
    ['Material.006']: THREE.MeshStandardMaterial
    innercity: THREE.MeshStandardMaterial
    outercity: THREE.MeshStandardMaterial
  }
}

const Camera = (props: any) => {
  const dispatch = useDispatch();
  const { step, menuStep, building } = props

  // keep track of the scrolled value in pixels
  const [scrollY, setScrollY] = useState(0)

  // create a Spring fo the zoom animation
  const [{ y }, set] = useSpring(() => ({ y: scrollY, config: config.slow }))


  // used on every wheel scroll event 
  const fn = useCallback(

    ({ movement: [, my], first, last }) => {

      if (building !== "") return

      const updateStepBasedOnScroll = (scroll: Number) => {

        if (scroll > 0 && scroll <= 50) {
          dispatch(jumpTo(0))
        }

        if (scroll > 50 && scroll <= 300) {
          dispatch(jumpTo(1))
        }

        if (scroll > 300 && scroll <= 750) {
          dispatch(jumpTo(2))
        }

        // if (scroll > 750 && scroll <= 1000) {
        //   dispatch(jumpTo(3))
        // }
      }

      const newY = clamp(scrollY + my, 0, 1000)

      set({ y: newY }) // update Spring

      updateStepBasedOnScroll(newY)

      if (last) {
        setScrollY(newY)
      }
    },
    [building, dispatch, scrollY, set]
  )

  useGesture({ onWheel: fn }, { domTarget: window })

  const ref = useRef<THREE.OrthographicCamera>()
  const { setDefaultCamera } = useThree()
  useEffect(() => void setDefaultCamera(ref.current as THREE.OrthographicCamera), [])
  useFrame(() => {
    ref.current!.updateMatrixWorld()
    ref.current!.updateProjectionMatrix()
  })

  // If the Step is different from the step clicked in the menu, adjust Spring and scrolled value
  if (step !== menuStep) {
    switch (menuStep) {
      case 0:
        set({ y: 0 })
        setTimeout(() => setScrollY(0), 0)// not sure why this works
        break;
      case 1:
        set({ y: 50 })
        setTimeout(() => setScrollY(50), 0)// not sure why this works
        break;

      case 2:
        set({ y: 750 })
        setTimeout(() => setScrollY(750), 0)// not sure why this works
        break;
      // case 3:
      //   set({ y: 750 })
      //   setTimeout(() => setScrollY(750), 0)// not sure why this works
      //   break;
    }
  }

  // consolidate states from navbar and 3d model
  dispatch(jumpTo(menuStep))
  const zoom = y.to([0, 1000], [2, 12])

  return <a.orthographicCamera ref={ref} {...props} makeDefault={true} far={1000} near={0} rotation={[-Math.PI / 2, 0, 0]} zoom={zoom} />
}

const GroupRot = (props: any) => {
  const { step, building } = props

  const [drag, setDrag] = useState(0);

  const [{ x }, set] = useSpring(() => ({ x: 0, config: config.slow }))

  const fn = useCallback(
    ({ movement: [mx, my], last }) => {
      if (last) {
        setDrag(drag + mx)
      }

      set({ x: drag + mx })
      return mx
    },
    [set, drag, setDrag]
  )
  useGesture({ onDrag: fn }, { domTarget: window })

  const ref = useRef<THREE.Group>()
  useFrame(() => {
    ref.current!.updateMatrixWorld()
  })

  useEffect(() => {
    if (step === 3) {
      switch (building) {
        case "A":
          set({ x: 0 })
          setTimeout(() => setDrag(0), 0)// not sure why this works
          break;
        case "B":
          set({ x: -200 })
          setTimeout(() => setDrag(-200), 0)// not sure why this works
          break;
        case "C":
          set({ x: 420 })
          setTimeout(() => setDrag(420), 0)// not sure why this works
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building, step])

  // console.log(x.get())
  const rot = x.to([0, 1000], [0, Math.PI * 2])

  return <a.group ref={ref} {...props} rotation-y={rot} />
}

type ActionName = 'Action.005'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

const Model = (props: any) => {
  const { step, menuStep, building, floor } = props
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF('/base_levels.gltf') as GLTFResult
  return (
    <Provider store={store}>

      <group ref={group} {...props} dispose={null} >
        <group name="Camera" position={[-215.8307647705078, 233.81256103515625, -328.81085205078125]} rotation={[2.02, -0.23, 2.69]}>
          <Provider store={store}>
            <Camera step={step} menuStep={menuStep} building={building} />
          </Provider>
        </group>
        <GroupRot step={step} building={building}>
          {step >= 3 &&
            <>
              {building === "B" &&
                <>
                  <Floor name="B1" scene="605c220e-1bab-4f98-a274-1418feb96119" floor={floor} material={materials['Material.006']} geometry={nodes.Plane009.geometry} position={[0, 6.88, 0]} />
                  <Floor name="B2" scene="605c220e-1bab-4f98-a274-1418feb96119" floor={floor} material={materials['Material.006']} geometry={nodes.Plane014.geometry} position={[0, 6.88, 0]} />
                  <Floor name="B3" scene="605c220e-1bab-4f98-a274-1418feb96119" floor={floor} material={materials['Material.006']} geometry={nodes.Plane017.geometry} position={[0, 6.88, 0]} />
                  <Floor name="B4" scene="605c220e-1bab-4f98-a274-1418feb96119" floor={floor} material={materials['Material.006']} geometry={nodes.Plane020.geometry} position={[0, 6.88, 0]} />
                  <Floor name="B5" scene="605c220e-1bab-4f98-a274-1418feb96119" floor={floor} material={materials['Material.006']} geometry={nodes.Plane023.geometry} position={[0, 6.88, 0]} />
                  <Floor name="B6" scene="605c220e-1bab-4f98-a274-1418feb96119" floor={floor} material={materials['Material.006']} geometry={nodes.Plane028.geometry} position={[0, 6.88, 0]} />
                  <Floor name="B7" scene="605c220e-1bab-4f98-a274-1418feb96119" floor={floor} material={materials['Material.006']} geometry={nodes.Plane031.geometry} position={[0, 6.88, 0]} />
                  <Floor name="B8" scene="605c220e-1bab-4f98-a274-1418feb96119" floor={floor} material={materials['Material.006']} geometry={nodes.Plane034.geometry} position={[0, 6.88, 0]} />
                </>}
              {building === "C" &&
                <>
                  <Floor name="C1" scene="e90baf33-3cca-4b0a-b701-374761223f32" floor={floor} material={materials['Material.006']} geometry={nodes.Plane012.geometry} position={[0, 6.88, 0]} />
                  <Floor name="C2" scene="e90baf33-3cca-4b0a-b701-374761223f32" floor={floor} material={materials['Material.006']} geometry={nodes.Plane015.geometry} position={[0, 6.88, 0]} />
                  <Floor name="C3" scene="e90baf33-3cca-4b0a-b701-374761223f32" floor={floor} material={materials['Material.006']} geometry={nodes.Plane018.geometry} position={[0, 6.88, 0]} />
                  <Floor name="C4" scene="e90baf33-3cca-4b0a-b701-374761223f32" floor={floor} material={materials['Material.006']} geometry={nodes.Plane021.geometry} position={[0, 6.88, 0]} />
                  <Floor name="C5" scene="e90baf33-3cca-4b0a-b701-374761223f32" floor={floor} material={materials['Material.006']} geometry={nodes.Plane026.geometry} position={[0, 6.88, 0]} />
                  <Floor name="C6" scene="e90baf33-3cca-4b0a-b701-374761223f32" floor={floor} material={materials['Material.006']} geometry={nodes.Plane029.geometry} position={[0, 6.88, 0]} />
                  <Floor name="C7" scene="e90baf33-3cca-4b0a-b701-374761223f32" floor={floor} material={materials['Material.006']} geometry={nodes.Plane032.geometry} position={[0, 6.88, 0]} />
                  <Floor name="C8" scene="e90baf33-3cca-4b0a-b701-374761223f32" floor={floor} material={materials['Material.006']} geometry={nodes.Plane035.geometry} position={[0, 6.88, 0]} />
                  <Floor name="C9" scene="e90baf33-3cca-4b0a-b701-374761223f32" floor={floor} material={materials['Material.006']} geometry={nodes.Plane038.geometry} position={[0, 6.88, 0]} />
                  <Floor name="C10" scene="e90baf33-3cca-4b0a-b701-374761223f32" floor={floor} material={materials['Material.006']} geometry={nodes.Plane041.geometry} position={[0, 6.88, 0]} />
                </>}

              {building === "A" &&
                <>
                  <Floor name="A1" scene="0d66e683-ec33-492b-bc65-4f57582d1274" floor={floor} material={materials['Material.006']} geometry={nodes.Plane083.geometry} position={[0, 6.93, 0]} />
                  <Floor name="A2" scene="0d66e683-ec33-492b-bc65-4f57582d1274" floor={floor} material={materials['Material.006']} geometry={nodes.Plane084.geometry} position={[0, 6.93, 0]} />
                  <Floor name="A3" scene="0d66e683-ec33-492b-bc65-4f57582d1274" floor={floor} material={materials['Material.006']} geometry={nodes.Plane085.geometry} position={[0, 6.93, 0]} />
                  <Floor name="A4" scene="0d66e683-ec33-492b-bc65-4f57582d1274" floor={floor} material={materials['Material.006']} geometry={nodes.Plane086.geometry} position={[0, 6.93, 0]} />
                  <Floor name="A5" scene="0d66e683-ec33-492b-bc65-4f57582d1274" floor={floor} material={materials['Material.006']} geometry={nodes.Plane089.geometry} position={[0, 6.93, 0]} />
                  <Floor name="A6" scene="0d66e683-ec33-492b-bc65-4f57582d1274" floor={floor} material={materials['Material.006']} geometry={nodes.Plane090.geometry} position={[0, 6.93, 0]} />
                  <Floor name="A7" scene="0d66e683-ec33-492b-bc65-4f57582d1274" floor={floor} material={materials['Material.006']} geometry={nodes.Plane091.geometry} position={[0, 6.93, 0]} />
                </>}
            </>
          }
          {step === 2 &&
            <>
              <Building name="C" material={materials['Material.006']} step={step} geometry={nodes.building_c.geometry} position={[0, 6.88, 0]} />
              <Building name="A" material={materials['Material.006']} step={step} geometry={nodes.building_a.geometry} position={[0, 6.88, 0]} />
              <Building name="B" material={materials['Material.006']} step={step} geometry={nodes.building_b.geometry} position={[0, 6.88, 0]} />
            </>}

          {(step === 3 || step === 4) &&
            <>
              {(building === "A" || building === "B") && <Building name="C" material={materials['Material.006']} geometry={nodes.building_c.geometry} position={[0, 6.88, 0]} />}
              {(building === "C" || building === "B") && <Building name="A" material={materials['Material.006']} geometry={nodes.building_a.geometry} position={[0, 6.88, 0]} />}
              {(building === "A" || building === "C") && <Building name="B" material={materials['Material.006']} geometry={nodes.building_b.geometry} position={[0, 6.88, 0]} />}
            </>}

          {props.step < 2 &&
            <Block material={materials['Material.006']} geometry={nodes.block.geometry} position={[0, 6.88, 0]} />}


          <group position={[222.28, -180.5, 106.97]} scale={[0.99, 0.99, 0.99]}>
            <mesh material={materials.buildings} geometry={nodes.Areaslanduse.geometry} />
            <mesh material={materials['Material.006']} geometry={nodes.Areaslanduse_1.geometry} />
          </group>
          {/* <mesh
            material={materials.buildings}
            geometry={nodes.city.geometry}
            position={[222.28, -180.5, 106.97]}
            scale={[0.99, 0.99, 0.99]}
          /> */}

          <mesh
            material={materials.innercity}
            geometry={nodes.plane_inner.geometry}
            position={[222.28, -180.5, 106.97]}
            scale={[0.99, 0.99, 0.99]}
          />


          {props.step < 3 &&
            <mesh
              material={materials.outercity}
              geometry={nodes.plane_outer.geometry}
              position={[222.28, -180.5, 106.97]}
              scale={[0.99, 0.99, 0.99]}
            />
          }
          <pointLight
            castShadow
            intensity={4}
            position={[900, 500, 900]}
            shadow-mapSize-width={2048}
            shadow-mapSize-height={2048}
          />

        </GroupRot>
      </group>
    </Provider>
  )
}

useGLTF.preload('/base_levels.gltf')
export default Model
