import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { selectStep } from '../app/steps/stepsSlice';
import Block from '../views/Block';
import Building from '../views/Building';
import Floor from '../views/Floor';
import Home from '../views/Home';
import Site from '../views/Site';
import Unit from '../views/Unit';

const pageDefaultWrapper = (page: JSX.Element) => {
    return <div style={{ width: '20vw', position: 'absolute', top: '0', bottom: '0', right: '0', overflow: 'hidden', zIndex: 2 }}>
        <div className={'padding-description'}>
            {page}
        </div>
    </div>
}

const OverlayPages = () => {
    const step = useSelector(selectStep);
    let page

    switch (step) {
        case 0:
            page = (
                <Home />
            );
            break;
        case 1:
            page = pageDefaultWrapper(<Site />)
            break;
        case 2:
            page = pageDefaultWrapper(<Block />)
            break;
        case 3:
            page = pageDefaultWrapper(<Building />)
            break;
        case 4:
            page = pageDefaultWrapper(<Floor />)
            break;

        default:
            if (step >= 5) {
                page = (
                    <div style={{ width: '100%', position: 'absolute', top: '0', bottom: '0', right: '0', overflow: 'hidden', zIndex: 2 }}>
                        <div style={{ width: '100%' }}><Unit></Unit></div>
                    </div>
                );
            } else {
                page = <></>
            }
            break;
    }

    return (
        page
    )
}


export default OverlayPages;

