import { Button, Col, Row } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import '../App.css';
import {
  resetTo
} from '../app/steps/stepsSlice';
import CSS from 'csstype';

const Home = () => {
  const dispatch = useDispatch();

  const style: CSS.Properties = {
    width: "50vw",
    height: "40vh",
    position: "fixed",
    top: "60vh",
    right: "1vw",
    zIndex: 2
  }

  return (
    <Row className="fadeIn" style={style}  >
      <Col >
        <Row>
          <Col>
            <h3 style={{ marginBottom: 0, fontSize: 96 }}>Washington DC</h3>
            <p style={{ fontSize: 24 }}> The neighborhood for DC’s next generation of leaders, connecting Capitol Hill and Gallery Place in the heart of the city</p>
            <Button className='nav-hover' type="link" style={{ fontSize: 36, color: '#E55010', fontWeight: 'bold', fontStyle: 'normal', textShadow: ' -1px -1px 3px rgba(51, 51, 51, 0.08)' }}
              onClick={() => dispatch(resetTo(1))} >
              EXPLORE THE SITE
          </Button>
          </Col>
        </Row>

      </Col>
    </Row>
  );
}

export default Home;

