
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { colorSelected, colorUnselected } from '../App';
import { addHovered, resetTo, selectBuilding } from '../app/steps/stepsSlice';


const Building = (props: any) => {
    const [hovered, setHover] = useState(false)
    const dispatch = useDispatch();


    useEffect(() => {
      dispatch(addHovered(hovered))
    }, [hovered])
    
    return (
          <mesh
            {...props}
            onClick={(e) => {e.stopPropagation(); dispatch(resetTo(3)); dispatch(selectBuilding(e.eventObject['name']))}}
            onPointerOver={(e) => {e.stopPropagation(); setHover(true);}}
            onPointerOut={(e) => {e.stopPropagation(); setHover(false);}}>

            { (props.name === props.builindg || props.step === 2) &&
              <meshStandardMaterial color={ hovered ? colorSelected : colorUnselected } />
            } 
          </mesh>
    )
}

export default Building
